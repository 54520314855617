import React from 'react'
import './App.css'
import Home from './pages/Home'
import Header from './components/Header'
import { Row, Grid } from 'react-flexbox-grid'
import Footer from './components/Footer'
import { BrowserRouter, Route } from 'react-router-dom'
import Container from '@material-ui/core/Container';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Route exact path="/" component={() => <Header type={'home'} />} />
        <br />
       {/*  <Container fixed> */}
            <Row>
              <Route exact path="/" component={Home} />
            </Row>
       {/*  </Container> */}
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
