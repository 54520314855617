import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { stack as Menu } from 'react-burger-menu'
import Button from '@material-ui/core/Button'
import Scroll from 'react-scroll'
import { Link, animateScroll as scroll } from 'react-scroll'


import PropTypes from 'prop-types'
import './styles/Navbar.css'
import Fab from '@material-ui/core/Fab'
class Navbar extends Component {
  showSettings = event => {
    event.preventDefault()
  }
  render() {
    const { type } = this.props
    let burgerMenuClass = 'burger-menu'
    if (type !== 'home') {
      burgerMenuClass += '-enterprise'
    }
    return (
      <div>
        <div className={burgerMenuClass}>
          <Menu>

            <a href={process.env.REACT_APP_PANEL_URL + '/where_is_my_shipment'}>DÓNDE ESTÁ MI ENVÍO</a>

            <Link className="servicios" to="why-enviamelo" spy={true} smooth={true} duration={500} >SERVICIOS</Link>


            <a href="https://documenter.getpostman.com/view/2178004/T1DjjK9j?version=latest" >API </a>






             <a target="_blank" href="https://enviamelo-woo-docs.netlify.app/">Woocommerce</a>
             <a target="_blank" href="https://ayuda.tiendanube.com/es_ES/enviamelo/como-integrar-enviamelo-con-mi-tiendanube">TiendaNube</a>




              <a href="#">CONTACTO</a>





            <a href={process.env.REACT_APP_PANEL_URL + '/login'}>INICIA SESION </a>
            <a href={process.env.REACT_APP_PANEL_URL + '/register'}>CREAR CUENTA</a>
          </Menu>
        </div>
        <nav className="navbar">
          {/*         <img
                    src={
                   require('../resources/images/enviamelo-logo.svg')
                    }
                    className="mobile-logo"
                    alt="logo-white"
                  /> */}
          <div className="logo">
            <img
              src={require('../resources/images/enviamelo-logo.svg')}
              className="mobile-logo"
              alt="logo-white"
            />
          </div>
          <div className="navbar-brand">
            <NavLink exact to="/">
              {/*  <img
                src={
                  type === 'home'
                    ? require('../resources/images/enviamelo-logo.svg')
                    : require('../resources/images/logo-blue.svg')
                }
                className="logo"
                alt="logo-white"
              /> */}
            </NavLink>
          </div>
          <div className="navbar-menu">






            <ol className="list-items list-item1">
              <li>
                <a href={process.env.REACT_APP_PANEL_URL + '/where_is_my_shipment'}>DÓNDE ESTÁ MI ENVIO</a>
              </li>



              <li>
                <Link className="servicios" to="why-enviamelo" spy={true} smooth={true} duration={500} >SERVICIOS</Link>
              </li>
              <li>
                <a href="https://documenter.getpostman.com/view/2178004/T1DjjK9j?version=latest" >API </a>
              </li>


              <nav id="menu">
                  <ul>
                      <li><a href="#">INTEGRACIONES</a>
                            <ul>
                            <li className="nav"><a target="_blank" href="https://enviamelo-woo-docs.netlify.app/">Woocommerce</a></li>
                            <li className="nav"><a target="_blank" href="https://ayuda.tiendanube.com/es_ES/enviamelo/como-integrar-enviamelo-con-mi-tiendanube">TiendaNube</a></li>
                            </ul>
                      </li>
                  </ul>
              </nav>


              <li>

              <nav id="menu">
                  <ul>
                      <li><a href="#">CONTACTO</a>
                            <ul>
                            <li className="nav"><a target="_blank" href="https://api.whatsapp.com/send/?phone=5491162008674&text&app_absent=0">Atención Comercial</a></li>
                            <li className="nav"><a target="_blank" href="https://api.whatsapp.com/send/?phone=5491132413503&text&app_absent=0">Soporte Envíos</a></li>
                            <li className="nav"><a target="_blank" href="https://api.whatsapp.com/send/?phone=5491162008771&text&app_absent=0">Atención al Cliente</a> </li>
                            </ul>
                      </li>
                  </ul>
              </nav>


              </li>







              <li>
                <a href={process.env.REACT_APP_PANEL_URL + '/login'} >INICIA SESION</a>
              </li>
              <li>
                <Button
                  href={process.env.REACT_APP_PANEL_URL + '/register'}
                  variant="outlined"
                  color="primary"
                  className="register-nav"
                >
                  CREAR CUENTA
                </Button>
              </li>
              {/*    <a href="/#contacto">CREAR CUENTA</a> */}





            </ol>






          </div>
        </nav>
      </div>















    )
  }
}

Navbar.propTypes = {
  type: PropTypes.string
}

export default Navbar
