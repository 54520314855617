import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Navbar from './Navbar'
import './styles/Header.css'
import { Col } from 'react-flexbox-grid'
import Container from '@material-ui/core/Container';

import Fab from '@material-ui/core/Fab'

class Header extends Component {
  render() {
    const { type, registered } = this.props
    let className = 'app-header' + (registered ? ' align-center ' : '')
    switch (type) {
      case 'home':
        className += ' home'
        break
      case 'enterprise':
        className += ' enterprise'
        break
      default:
        className += ' home'
        break
    }

    return (
      <div>
        <header className={className}>
          <Navbar type={type} />
          <Container fixed>
          <div className="header-content ">
            <div className="left-content">
              {type === 'home' ? (
                <React.Fragment>
                  <React.Fragment>
                    <span>
                      <p>
                        <b>
                          SOMOS LA SOLUCIÓN INTEGRAL PARA TUS ENVÍOS E-COMMERCE 
                     
                        </b>
                      </p>

                      <p className="header-description">
                        <b>
                          {' '}
                          Te ofrecemos el mejor servicio al costo  <br />
                          mas conveniente
                        </b>
                      </p>
                      <p>
                        <Fab
                          variant="extended"
                          className="register-btn"
                          aria-label="add"  
                          href={ process.env.REACT_APP_PANEL_URL + '/register'}
                          color="primary"
                        >
                          Crear Cuenta
                        </Fab>
                      </p>
                    </span>
                  </React.Fragment>
                

                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Col xs={4} md={4}>
                    {/*   <img
                      className="isologotipo"
                      src={require('../resources/images/isologotipo.png')}
                      alt="stores"
                    /> */}
                  </Col>
                  <Col xs={8} md={8}>
                    <span className="span-campaign">
                      <b>LLEVA TU MARCA A LA CALLE</b>
                    </span>
                    {/*    <Button
                      className="btn-campain-desktop"
                      title={'Empezar Campaña'}
                      href="#quotation"
                    /> */}
                  </Col>
                  {/*       <Button
                    className="btn-campain-mobile"
                    title={'Empezar Campaña'}
                    size="xs"
                    href="#quotation"
                    white
                  /> */}
                </React.Fragment>
              )}
            </div>
            <div className="right-content">
            <div className="enviamelo-mobile">
            <React.Fragment>
                        <img
                    src={require('../resources/images/nuevo_logo.gif')}
                    alt="mobile gif"
                  />
                  </React.Fragment>
               {/*    <p><a href="#">Probá nuestra version Mobile</a></p> */}
            </div>
            </div>
            {/*   {type === 'home' ? (
              <div className="right-content">
                <img
                  src={require('../resources/images/ploti-car-1.png')}
                  alt="ploti car 1"
                />
              </div>
            ) : (
              <div className="right-content">
                <img
                  src={require('../resources/images/ploti-car-3.png')}
                  alt="ploti car 3"
                />
              </div>
            )} */}
          </div>
          </Container>
        </header>
      </div>
    )
  }
}

Header.propTypes = {
  type: PropTypes.string,
  registered: PropTypes.bool
}

export default Header
