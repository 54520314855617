import React, { Component, createRef } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Link, DirectLink, Element, animateScroll as scroll } from 'react-scroll'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import _ from 'lodash'
import 'rodal/lib/rodal.css'
import './styles/Home.css'


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      open: false,
      anchorRef: createRef()
    };
  }
  handleToggle() {
    console.log('previus state', this.state.open);
    console.log('anchorRef', this.state.anchorRef);
    this.setState({ open: !this.state.open });
  }

  handleClose(event) {
    // console.log('event' , event);
    if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  }

  render() {

    return (

      <div className=" home">
        <Element id='servicios' name="servicios"  >
          <Row className="app-what-is app-container-enviamelo">

            <Col xs={7} md={5}>
              <h1 className="app-title">
                <span className="app-orange-text-color">Entregamos tus ventas</span>
                <br />
                <span className="app-gray-text-color">EN TODO EL PAIS</span>
              </h1>
              {/*  <p className="app-gray-text-color app-lg-text app-subtitle">
          Retiramos tus envíos por donde nos indiques
          </p> */}
            </Col>

          </Row>
        </Element>

        <Grid container className="grid-enviamelo" spacing={3}>
          <Grid container className="app-container-enviamelo">
          <Grid item xs={12}>
            <br />
            <br />
            <br />
            </Grid>    
            
            <Grid item xs={6} className="first-grid">
              <ScrollAnimation animateIn="slideInRight" animateOnce={true} duration={2}>
                <Paper>
                  <img
                    src={require('../resources/images/truck.png')}
                    className="img-left"
                  />
                  <p className="app-gray-text-color app-lg-text app-display-text">
                  Retiros por domicilio o despacho en un punto dropoff
              </p>
                </Paper>
              </ScrollAnimation>

            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} className="second-grid">
              <ScrollAnimation animateIn="slideInRight" animateOnce={true} duration={2}>
                <Paper>
                  <img
                    src={require('../resources/images/man.png')}
                    className="img-left"
                  />
                  <p className="app-gray-text-color app-lg-text app-display-text">
                  Envíos a domicilio o a un punto de retiro
              </p>
                </Paper>
              </ScrollAnimation>

            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={2} />




            <Grid item xs={3} className="why-enviamelo">
              <h2 className="app-title why-enviamelo-title">
                <span className="app-white-text-color">
                  ¿POR QUE <br />
                  ENVIAMELO?
              </span>
              </h2>
            </Grid>

            <Grid item xs={9}>
            </Grid>

            <Grid item xs={12}>
            <br />
            <br />
            </Grid>






            <Grid item xs={4} className="no-paper">
              <img
                src={require('../resources/images/car.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Tenemos Flota Propia</span>
                <br />
                <br />
                Nos permite tener mayor control sobre tus envíos
                tus envíos
              </p>
            </Grid>





            
            <Grid item xs={4} className="no-paper">
              <img
                src={require('../resources/images/tablero.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Tracking de Seguimiento</span>
                <br />
                <br />
                Conocé la ubicación y estado de cada uno de tus envíos en tiempo real
              </p>
            </Grid>




            <Grid item xs={4} className="no-paper">
              <img
                src={require('../resources/images/map.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Panel de Control</span>
                <br />
                <br />
                Desde tu Panel vas a poder ver la información de todos tus envíos y realizar diversas gestiones
              </p>
            </Grid>




            


            <Grid item xs={3}>
            </Grid>



            <Grid item xs={3}>
              <img
                src={require('../resources/images/etiquetas.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Etiquetas</span>
                <br />
                <br />
                Imprimí las etiquetas de tus envíos individuales o de forma masiva
              </p>
            </Grid>








            <Grid item xs={3}>
              <img
                src={require('../resources/images/ahorra.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Contenido de tus Ordenes</span>
                <br />
                <br />
                Vas a poder imprimir junto con la etiqueta el contenido de tu orden de venta para facilitar el armado de tus envíos
              </p>
            </Grid>



            <Grid item xs={3}>
              <img
                src={require('../resources/images/integra.png')}
                className="img-left inline-img"
              />
              <p className="app-white-text-color app-lg-text inline-p">
                <span>Logistica Inversa</span>
                <br />
                <br />
                Vas a poder realizar envíos para Cambios y/o Devoluciones
              </p>
            </Grid>




            <Grid item xs={8} className="logistica-integral">
              <h2 className="app-title logistica-integral-title">
                <span className="app-orange-text-color">FULFILLMENT</span>
                <br />
                <span className="app-orange-text-color">GESTION INTEGRAL</span>
                <br />
                <span className="app-gray-text-color">DE TUS ENVÍOS</span>
              </h2>
            </Grid>


          </Grid>


        </Grid>


        <center>
        <Grid container className="grid-footer app-container-enviamelo" spacing={3}>

          <Grid item xs={12} sm={4}>
            <Paper className="paper-middle last-box">
              <center><img
                src={require('../resources/images/package.png')}
                className="img-center"
              /></center>
              <p className="app-gray-text-color app-lg-text">
                {/*   Embalamos y etiquetamos
                tus ventas, y las entregamos
                con nuestro  <span className="app-orange-text-color">Servicio Puerta a 
                Puerta </span> */}
                Almacenamos y administramos tu stock
              </p>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Paper className="paper-middle last-box">
              <center><img
                src={require('../resources/images/hand.png')}
                className="img-center"
              /></center>
              <br />
              <p className="app-gray-text-color app-lg-text">
                {/*     Este, <span className="app-orange-text-color">servicio es a medida, </span>
                nos adaptamos a todos tus
                requerimientos. */}
                Optimizamos los tiempos de preparación de tus envíos y agilizamos las entregas a tus clientes
              </p>
              <br />

            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="paper-middle last-box">
              <center><img
                src={require('../resources/images/settings.png')}
                className="img-center"
              /></center>
              <br />
              <p className="app-gray-text-color app-lg-text">
                {/*      Ofrecemos, <span className="app-orange-text-color">simplificar la
                gestion de tu stock,</span> optimizar
                los tiempos de preparación  
                de tus envíos y agilizar las
                entregas a tus clientes. */}
                Embalamos y etiquetamos tus ventas
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className="paper-middle last-box">
              <center><img
                src={require('../resources/images/package.png')}
                className="img-center"
              /></center>
              <p className="app-gray-text-color app-lg-text">
                {/*                 Nuestro servicio de solución integral de stock te ofrece tus productos desde su origen,
                clasificados, y almacenados. <span className="app-orange-text-color">Controlar tu stock online </span> y administrarlo
                de acuerdo  a la */}
                Seguimiento online de órdenes y stock
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className="paper-middle last-box">
              <center><img
                src={require('../resources/images/pc.png')}
                className="img-center"
              /></center>
              <br />
              <p className="app-gray-text-color app-lg-text">
                {/*                 Nuestro servicio de solución integral de stock te ofrece tus productos desde su origen,
                clasificados, y almacenados. <span className="app-orange-text-color">Controlar tu stock online </span> y administrarlo
                de acuerdo  a la */}
                Servicios a medida, nos adaptamos a todos tus requerimientos y necesidades
              </p>
            </Paper>
          </Grid>


          {/* <Grid item xs={12} sm={12}>
            <h2 className="app-title registrate-title">
              <span className="app-orange-text-color">REGISTRATE</span>
              <br />
              <span className="app-gray-text-color"> Y ESCALÁ TU NEGOCIO</span>
            </h2>
            <br />
            <center>            <Fab
              href={process.env.REACT_APP_PANEL_URL + '/register'}
              variant="extended"
              className="demo-btn"
              aria-label="add"
              color="primary"
            >
              ¡QUIERO UNA DEMO!
                        </Fab></center>

          </Grid> */}

        </Grid>
        </center>



        <Fab color="primary" onClick={() => this.handleToggle()} aria-label="add" ref={this.state.anchorRef} className="whatsapp" >
          <i className="fa fa-whatsapp fa-2x" aria-hidden="true" />
        </Fab>


        <Popper open={this.state.open} anchorEl={this.state.anchorRef.current} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <MenuList>
                  <MenuItem disableTouchRipple={true} >
                    <Fab
                      href={process.env.REACT_APP_WS_ATENCION_COMERCIAL}
                      variant="extended"
                      aria-label="add"
                      className="ws-button"
                      color="primary"
                    >
                      Atención Comercial
                        </Fab>
                  </MenuItem>
                  <MenuItem disableTouchRipple={true}>
                    <Fab
                      href={process.env.REACT_APP_WS_SOPORTE_ENVIOS}
                      variant="extended"
                      aria-label="add"
                      className="ws-button"
                      color="primary"
                    >
                      Soporte Envíos
                    </Fab>
                  </MenuItem>
                  <MenuItem disableTouchRipple={true}>
                    <Fab
                      href={process.env.REACT_APP_WS_ATENCION_CLIENTE}
                      variant="extended"
                      aria-label="add"
                      className="ws-button"
                      color="primary"
                    >
                      Atención al Cliente
                    </Fab>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>

            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}
