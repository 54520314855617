import React, { Component } from 'react'
import './styles/Footer.css'
import { Link, animateScroll as scroll } from 'react-scroll'
import { Row, Col } from 'react-flexbox-grid'
import terminos from '../resources/pdf/Terminos_y_Condiciones_Enviamelo_090919.pdf'
import politica from '../resources/pdf/Politica_de_Privacidad_Enviamelo_090919.pdf'
export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content-footer">
          <Row between="xs" start="xs">

            <Col xs={4} >

              <div className="social-container">
                {/*   <a href="#">
            <i className="fa fa-facebook-f fa-2x" aria-hidden="true" />
          </a> */}
                <a href="https://instagram.com/enviamelo.com.ar?igshid=t5v88mbkp6sq">
                  <i className="fa fa-instagram fa-2x" aria-hidden="true" />
                </a>
                {/*   <a href="#">
            <i className="fa fa-twitter fa-2x" aria-hidden="true" />
          </a> */}
              </div>
              <span id="contacto" className="footer-copy">
                <img
                  src={require('../resources/images/enviamelo-logo.svg')}
                  className="mobile-logo"
                  alt="logo-white"
                />
                <p>Copyright 2019 - Todos los derechos reservados</p>
                <a href="https://ecomerciar.com" class="powered" target="_blank">Powered by Ecomerciar</a>
              </span>
            </Col>
            <Col xs={4} >
              <ol className="list-footer">
                <li className="title-list">
                  <b> PANEL </b>
                </li>
                <li>
                  <a href={process.env.REACT_APP_PANEL_URL + '/register'} >Crear cuenta</a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_PANEL_URL + '/login'} >Iniciar Sesión</a>
                </li>
              </ol>
            </Col>
            <Col xs={4} >
              <ol className="list-footer">
                <li className="title-list">
                  <b>SECCIONES</b>
                </li>
                <li>
                  <a href={politica}>Politica de privacidad</a>
                </li>
                <li>
                  <a href={terminos}>Terminos y condiciones</a>
                </li>
                <li>
                  <a href={process.env.REACT_APP_PANEL_URL + '/where_is_my_shipment'}>Dónde está mi envío</a>
                </li>
                <li>
                  <Link activeClass="active" className="servicios" to="servicios" spy={true} smooth={true} duration={500} >Servicios</Link>

                </li>
                <li>
                  <a href="https://documenter.getpostman.com/view/2178004/T1DjjK9j?version=latest">API</a>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
      </footer>
    )
  }
}
